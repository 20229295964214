import revive_payload_client_afPY45IgJN from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__idb_do7xkmej3llht3tdetxq3e6owi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_riIlGT2Gwo from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__idb_do7xkmej3llht3tdetxq3e6owi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_nMjNuzGcsj from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__idb_do7xkmej3llht3tdetxq3e6owi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_4f5OG8U3lv from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__idb_do7xkmej3llht3tdetxq3e6owi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Fi0ihPcjkc from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__idb_do7xkmej3llht3tdetxq3e6owi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_nUoKqHFi1K from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__idb_do7xkmej3llht3tdetxq3e6owi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_Fopw1r3Ul7 from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__idb_do7xkmej3llht3tdetxq3e6owi/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_A6zUKdqHvw from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__idb_do7xkmej3llht3tdetxq3e6owi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_CKvh3sftvX from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__idb_do7xkmej3llht3tdetxq3e6owi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_MWXYq6RBwx from "/app/node_modules/.pnpm/nuxt-bugsnag@8.3.1_@bugsnag+core@8.2.0_magicast@0.3.5_rollup@4.32.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_client_O82MEhe1N4 from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.32.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_Ow5jGdkCAA from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.4_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_jikireog3thvg32je3psfgzfre/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_a6pMDs8DnN from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.4_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_jikireog3thvg32je3psfgzfre/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_FKAWtrvXoL from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.4_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_jikireog3thvg32je3psfgzfre/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_oK6b0iB905 from "/app/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@2.4.2_ter_4czfminfcf7wiwws6krvxe6eye/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import posthog_client_fUucxKWhZE from "/app/plugins/posthog.client.ts";
import socket_client_qBDI6bzE0L from "/app/plugins/socket.client.ts";
import ssg_detect_ARRxGbEufg from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.4_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_jikireog3thvg32je3psfgzfre/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_afPY45IgJN,
  unhead_riIlGT2Gwo,
  router_nMjNuzGcsj,
  payload_client_4f5OG8U3lv,
  navigation_repaint_client_Fi0ihPcjkc,
  check_outdated_build_client_nUoKqHFi1K,
  view_transitions_client_Fopw1r3Ul7,
  chunk_reload_client_A6zUKdqHvw,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CKvh3sftvX,
  plugin_MWXYq6RBwx,
  plugin_client_O82MEhe1N4,
  plugin_wy0B721ODc,
  switch_locale_path_ssr_Ow5jGdkCAA,
  route_locale_detect_a6pMDs8DnN,
  i18n_FKAWtrvXoL,
  plugin_oK6b0iB905,
  posthog_client_fUucxKWhZE,
  socket_client_qBDI6bzE0L,
  ssg_detect_ARRxGbEufg
]